/*resellersignup*/
.illustrator-container {
  /* width: 100%;
    height: 100vh; */
  display: flex;
  align-items: center;
}

.image-position {
  max-height: 100%;
  max-width: 100%;
}
.Main-container {
  width: 100%;
  min-height: 100px;
}

.Reseller-signup-main-form-container {
  width: 60%;
  height: 100vh;
  margin-left: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.reseller-signup-container1 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.option-container {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: left;
}
.option-container select:hover {
  cursor: pointer;
}

.option-container select {
  letter-spacing: 0.5px;
  word-spacing: 1px;
  font-size: 10px;
  border: none;
}

.reseller-signup-container2 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  text-align: left;
}

.reseller-signup-container2 span {
  line-height: normal;
  font-size: 40px;
  font-weight: bolder;
  margin-bottom: 0;
  margin-top: 0;
  letter-spacing: 0.5px;
}

.reseller-signup-container3 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.reseller-signup-container3 label {
  font-size: 12px;
  letter-spacing: 0.5px;
  color: gray;
  margin-bottom: 10px;
  margin-top: 20px;
}
.reseller-signup-container4 {
  width: 100%;
  margin-top: 40px;
}
.reseller-signup-police-container {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /*justify-content: end;*/
}

.reseller-signup-police-container p {
  font-size: 11px;
  letter-spacing: 0.6px;
  color: rgb(156, 156, 156) !important;
}

.reseller-signup-police-container p span {
  font-weight: 500;
  letter-spacing: 0.6px;
  font-size: 10px;
  color: black;
}

/* text fields style */
.textfield-iconStyle {
  font-size: 20px !important;
  color: rgb(156, 156, 156) !important;
}

.makethiswhite {
  color: rgb(144, 144, 144) !important;
}
