/* .paypal-input-contianer{
    width: 90%;
    min-height: 70px;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.paypal-input-container-style{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
} */


.stepper-payemnt-container-1{
    width: 100%;
    min-height: 70px;
    display: flex;
    justify-content: center;
}


.stepper-payemnt-buttoncontainer{
    width: 90%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-summary-container-1{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.order-summary-container-2{
    width: 85%;
    height: 35px;
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    align-items: center;
    border: 1px solid rgb(244, 244, 244);
    border-radius: 5px;
    background-color: rgb(244, 244, 244);
    margin-top: 10px;
    margin-bottom: 10px;
}

.deleteiconStyle{
    color: red;
}



/*order fonts*/

.order-summary-names{
    font-size: 12px;
    font-weight: bold;
}

.order-summary-count-box{
    width: 30px;
    height: 20px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: #fff;
    text-align: center;
}


.payment-order-summary-totalcontainer{
    border-top: 1px solid rgb(214, 214, 214);
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 3%;
    margin-right: 3%;
}


.order-summary-totalamt-contianer{
    width: 90%;
    height: 35px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
}

.order-summary-btn-contianer{
    width: 90%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}