.MuiCardHeader-title{
    font-size: 16px;
}
.MuiCardHeader-subheader{
    font-size: 13px;
}
.CannotPortText{
    color: red;
}
.CanPortText{
    color: green;
}