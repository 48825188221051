.reseller-billing-container {
  border: 1px solid rgb(205, 205, 205);
  border-radius: 10px;
  width: 100%;
  min-height: max-content;
}
.reseller-billing-address-container {
  border: 1px solid rgb(205, 205, 205);
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.reseller-order-summary {
  width: 100%;
  height: auto;
  border: 1px solid rgb(205, 205, 205);
  border-radius: 10px;
}
.reseller-order-summary-totalorder {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.reseller-ordersummary-confirmedbtn {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
}

.reseller-billing-container p {
  font-size: 14px;
}
.reseller-billing-text-title p {
  font-size: 14px;
}
.reseller-billing-confirmedbtn {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cost-contianer {
  width: 100%;
  height: 60px;
  background-color: rgb(244, 244, 244);
  border-radius: 10px;
}

.cost-text-style {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cost-text-style p {
  padding-left: 20px;
  padding-right: 20px;
}

.stepper-label-style {
  font-size: 12px;
  font-weight: 600;
  color: #232333;
}
