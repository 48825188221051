.cardleft-section .cardSno {
    display: flex !important;
    align-items: center !important;
    font-size: 20px;
    font-weight: bold;
    margin-Right: 10px;
}

.cardleft-section {
    display: flex !important;
    align-items: center !important;
}

.cardName {
    font-weight: bold;
}


.cardassign {
    margin-top: 10px !important;
    /* background: #e6f2ef !important;
    border: 3px solid #7dbca9 !important; */
    border-radius: 15px !important;
    max-width: 500px;
    margin: 0 auto
}

.cardPreference {
    margin-top: 10px !important;
    /* background: #f0f1f3 !important;
    border: 3px solid #dcdfe3 !important; */
    border-radius: 15px !important;
    max-width: 500px;
    margin: 0 auto;

}

.cardIVR {


    margin-top: 10px !important;
    /* background: #e7f1fd !important;
    border: 3px solid #adcaf1 !important; */
    border-radius: 15px !important;
    max-width: 500px;
    margin: 0 auto;


}

.cardcontent {

    display: flex;
    justify-content: space-between;
}

.cardcontainer {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    margin-top: 25px !important;

}

.provisiontxt {
    font-weight: bold !important;
    /* text-align: 'left' !important; */
    margin-left: 6% !important;

}
.provisionsubtxt{

    margin-left: 29% !important;
}
