.approvalcompany_main_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.approvalpage_formcontainer{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
   
}

.approvalpage_formcontainer_fieldstyle{
    padding: 10px;
    width: 70%;
    min-height: 300px;
}

.approvalpage_logo_container{
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.approvalpage_billingtype_container{
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.approvalpage_switch_style{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
}

.approval_page_modal{
    min-width: 250px;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(204, 204, 204);
}

.approval_page_modal_button{
    background-color: white;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}