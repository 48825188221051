.stepper-logo-contianer {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.steps-alignmnet {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
}

.stepper-appbar {
  border-bottom: 1px solid #b3b3b3;
}

.stepper-illustrator-container {
  width: 100%;
  height: calc(100vh - 101px);
  object-fit: cover;
}
.stepper-form-container {
  min-height: calc(100vh - 101px);
}
.button-container-next {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 100px;
}
.button-container-next:hover {
  cursor: pointer;
}
.next-back-container-position {
  margin-left: 30px;
}

.btn-hover-effect:hover {
  cursor: pointer;
}

/* stepper form element styles */

.stepper-title-containers {
  margin: 30px 15px 15px 15px;
}

.stepper-input-label-margin {
  margin: 10px 20px;
}

/* stepper box styles and shadow */
.stepper-box-shadow-style {
  box-shadow: 8px 8px 10px #e9e9e9;
}

/* stepper-border-style */
.stepper-border-style {
  border: 1px solid rgb(205, 205, 205);
  border-radius: 10px;
}
.stepper-main-contianer1 {
  width: 100%;
  min-height: 100px;
}

.stepper-main-contianer2 {
  width: 100%;
  min-height: 200px;
}

/*stepper-label and input container*/
.stepper-input-element-1-container {
  width: 100%;
  min-height: 70px;
}

/*stepper form button style*/

.stepper-arrow-btn-position-container {
  width: 170px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 40px;
}

.stepper-btn-outer-style {
  width: 70px;
  height: 50px;
  border-radius: 20px;
  background-color: #4682b4;
  position: relative;
  box-shadow: 8px 8px 15px #b3e1ff;
}
.stepper-btn-outer-style:hover {
  cursor: pointer;
}
.stepper-btn-inner-style {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 50px;
}
.arrow-color {
  color: #fff;
}

/* illustrator */

.illustrator-height {
  height: calc(100vh - 100px) !important;
  width: 100%;
}

.background-container {
  object-fit: cover;
  position: fixed;
  z-index: 9999;
  width: 40%;
  height: 100vh;
  object-fit: cover;
}

.background-img {
  height: 100vh;
  background-image: url('../../../../assets/Cost Summary Illustration.svg');
  background-repeat: no-repeat;
}
