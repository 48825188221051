* {
  font-family: 'mr-eaves-xl-modern' !important;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.error {
  font-size: 12px;
  color: red;
  margin-top: 5px;
}

/* bold title */
#title1 {
  font-size: 2rem;
  font-weight: 900;
}

#h1 {
  font-size: 3rem;
  font-weight: 900;
  margin: 0;
}

#p1 {
  font-size: 20px;
  color: gray;
  font-weight: 100;
  margin: 0;
}

#text1 {
  font-size: 2rem;
  font-weight: 900;
}

#text2 {
  font-size: 16px;
}

/*workgroups*/
.workGroups-main-container {
  width: 100%;
  min-height: 100px;
}

.worksGroup-border-Container {
  width: 100%;
  height: auto;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 10px;
}

.workgroups-data-cotnainer {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(220, 220, 220);
}

.workgroups-column-1 {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workgroups-column-1 p {
  font-size: 12px;
  font-weight: 900;
}

.workgroups-column-2 {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.workgroups-column-2 span {
  font-size: 10px;
  font-weight: 900;
}

.workgroups-column-3 {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrowforward:hover {
  cursor: pointer;
}

/*workgroups*/

/*workgroups1*/
.workGroups-head-container {
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 10% 10% 40% 10% 10% 20%;
  margin-bottom: 10px;
}

.workGroup-head-items {
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.workGroup-head-items-1 {
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: 900 !important;
  justify-content: left;
  font-size: 14px;
}

.workGroup-head-items-2 {
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: 900 !important;
  justify-content: center;
  font-size: 14px;
}

.TextField-without-border-radius fieldset {
  border-radius: 20px;
}

.my-accordin-container-style {
  border-radius: 10px !important;
  background-color: #eaf6ff !important;
}

.my-accordin-details-container {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 0 !important;
}

.accordin-header-container {
  width: 100%;
}

.myfont1 {
  font-size: 12px !important;
  font-weight: 700 !important;
  display: inline-flex;
  vertical-align: middle;
  padding-left: 20px;
}

.users-list {
  border-bottom: 1px solid rgb(223, 223, 223);
}

.user-row-style {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.user-row-style-2 {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: flex-end;
}

.user-row-user-name {
  font-size: 12px !important;
  font-weight: 700 !important;
}

.workgroup-2-container-style {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.workgroup2-column-2 {
  display: flex;
  align-items: center;
  width: 100%;
}

.circleadd {
  position: relative;
  display: flex;
  align-items: center;
}

.circleaddinside {
  position: absolute;
  top: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 900;
}

.workgroup3-column-3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.modal-container-1 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-title-font1 {
  font-weight: 500;
  font-size: 16px;
}

.modal-title-font2 {
  font-weight: 1000;
  font-size: 18px;
}

.modal-search-container {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
}

.closeIconHover:hover {
  cursor: pointer;
}

.forwardiconstyle:hover {
  cursor: pointer;
}

/*workgroups1*/

/*usermanagement*/
.user-management-Grid-main-contianer {
  display: grid;
  grid-template-columns: 90px calc(100% - 90px);
}

.user-management-Grid-item1 {
  width: 100%;
}

.user-management-Grid-item2 {
  width: 100%;
  padding: 0;
}

.css-19kzrtu {
  padding: 0 !important;
}

.usermanagement-main-container {
  width: 100%;
  min-height: 100vh;
}

.usermanagement-side-container {
  width: 100%;
  min-height: 100vh;
  background-color: #4682b4;
}

.usermanagement-side-container-avatar-container {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.usermanagement-side-container-icons-container {
  width: 100%;
  height: 200vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.usermanagement-appbar {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.usermanagement-appbar-title {
  padding-left: 20px;
}

.usermanagement-appbar-logo {
  padding-right: 20px;
}

.usermanagement-hotizontal-tabs {
  width: 100%;
  height: 50px !important;
  background-color: #000000;
  display: flex;
  align-items: center;
}

.sidebar-icons-style {
  color: #fff;
}

/* removing default right side border in drawer */
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border: none !important;
}

/*usermanagement*/

/*resellersignup*/
.illustrator-container {
  /* width: 100%;
  height: 100vh; */
  display: flex;
  align-items: center;
}

.image-position {
  max-height: 100%;
  max-width: 100%;
}

.Main-container {
  width: 100%;
  min-height: 100px;
}

.Reseller-signup-main-form-container {
  width: 60%;
  height: 100vh;
  margin-left: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rsmf-container1 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.option-container {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.option-container select:hover {
  cursor: pointer;
}

.option-container select {
  letter-spacing: 0.5px;
  word-spacing: 1px;
  font-size: 10px;
  border: none;
}

.reseller-signup-container2 {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  text-align: left;
}

.reseller-signup-container2 span {
  line-height: normal;
  font-size: 40px;
  font-weight: bolder;
  margin-bottom: 0;
  margin-top: 0;
  letter-spacing: 0.5px;
}

.reseller-signup-container3 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.reseller-signup-container3 label {
  font-size: 12px;
  letter-spacing: 0.5px;
  color: gray;
  margin-bottom: 10px;
  margin-top: 20px;
}

.reseller-signup-container4 {
  width: 100%;
  margin-top: 40px;
}

.reseller-signup-police-container {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.reseller-signup-police-container p {
  font-size: 11px;
  letter-spacing: 0.6px;
  color: rgb(156, 156, 156) !important;
}

.reseller-signup-police-container p span {
  font-weight: 500;
  letter-spacing: 0.6px;
  font-size: 10px;
  color: black;
}

/* text fields style */
.textfield-iconStyle {
  font-size: 20px !important;
  color: rgb(156, 156, 156) !important;
}

.makethiswhite {
  color: rgb(144, 144, 144) !important;
}

.signup-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
}

.form-container {
  width: 500px;
  height: 450px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-logo-container {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-info-container {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signup-info-container h2 {
  font-size: 28px;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}

.signup-info-container p {
  font-size: 16px;
  letter-spacing: 0.7px;
  font-weight: 500;
  text-align: center;
}

.signup-button-container {
  width: 60%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*rsellerstepper*/
.stepper-logo-contianer {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.steps-alignmnet {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
}

/* .stepper-appbar {
   
} */

.stepper-illustrator-container {
  width: 100%;
  height: calc(100vh - 101px);
  object-fit: cover;
}

.stepper-form-container {
  min-height: calc(100vh - 101px);
}

.button-container-next {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 100px;
}

.button-container-next:hover {
  cursor: pointer;
}

.next-back-container-position {
  margin-left: 30px;
}

.btn-hover-effect:hover {
  cursor: pointer;
}

.button-position-contianer {
  margin-left: 30px;
  display: flex;
  width: 50%;
  height: 100px;
  justify-content: left;
  align-items: center;
}

/* stepper form element styles */

.stepper-title-containers {
  margin: 30px 15px 15px 15px;
}

.stepper-input-label-margin {
  margin: 10px 20px 15px 20px !important;
}

/* stepper box styles and shadow */
.stepper-box-shadow-style {
  box-shadow: 8px 8px 10px #e9e9e9;
}

/* stepper-border-style */
.stepper-border-style {
  border: 1px solid rgb(205, 205, 205);
  border-radius: 10px;
}

.stepper-main-contianer1 {
  width: 100%;
  min-height: 100px;
}

.stepper-main-contianer2 {
  width: 100%;
  min-height: 200px;
}

/*stepper-label and input container*/
.stepper-input-element-1-container {
  width: 100%;
  min-height: 70px;
}

/*stepper form button style*/

.stepper-arrow-btn-position-container {
  width: 170px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 40px;
}

.stepper-btn-outer-style {
  width: 70px;
  height: 50px;
  border-radius: 20px;
  background-color: #4682b4;
  position: relative;
  box-shadow: 8px 8px 15px #b3e1ff;
}

.stepper-btn-outer-style:hover {
  cursor: pointer;
}

.stepper-btn-inner-style {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 50px;
}

.arrow-color {
  color: #fff;
}

/* illustrator */

.illustrator-height {
  height: calc(100vh - 100px) !important;
  width: 100%;
}

/*rsellerstepper*/

/*accountdetals*/

.Account-details-container {
  width: 100%;
  height: 450px;
  border: 1px solid rgb(205, 205, 205);
  border-radius: 10px;
  margin-top: 30px;
}

.Admin-login-container {
  width: 100%;
  height: 350px;
  border: 1px solid rgb(205, 205, 205);
  border-radius: 10px;
  margin-top: 30px;
}

.stepper-label-style {
  color: rgb(157 157 157);
  font-size: 12px;
}

.stepper-account-details-btncontainer {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

/*accountdetails*/

/*appearance*/
.input-info-label {
  font-size: 12px;
  color: rgb(212, 212, 212);
}

.circle-upload-button-style {
  width: 70px;
  height: 70px;
  border: 2px dashed #4682b4;
  border-radius: 50%;
}

.circle-upload-button-style:hover {
  cursor: pointer;
}

.upload-btn-container-style {
  /* margin: 10px 30px 80px; */
  padding: 10px 40px;
  /* width: 100%;
  height: 100px;
  display: block;
  flex-direction: row;
  align-items: center; */
}

.stepper-appearance-color-scheme {
  margin: 10px 20px;
}

.color-choosing-button-style {
  width: 10px;
  height: 10px;
  border: 1px solid rgb(218, 218, 218);
  background-color: #fff;
  border-radius: 2px;
}

.stepper-appearance-label-style {
  color: rgb(143 140 140);
  font-size: 12px;
  margin-bottom: 10px;
}

.select-color-circles-style {
  width: 20px;
  height: 20px;
  border: 1px solid #4682b4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.select-color-container-style {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.circle-color1 {
  color: red;
  font-size: 35px !important;
}

.circle-color2 {
  color: green;
  font-size: 35px !important;
}

.circle-color3 {
  color: yellow;
  font-size: 35px !important;
}

.Appearance-btn-container-style {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Appearance-btn-container-style-1 {
  width: 90%;
}

/*appearance*/

/*blling*/
.reseller-billing-container {
  border: 1px solid rgb(205, 205, 205);
  border-radius: 10px;
  width: 100%;
  min-height: max-content;
}

.reseller-billing-address-container {
  border: 1px solid rgb(205, 205, 205);
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.reseller-order-summary {
  width: 100%;
  height: auto;
  border: 1px solid rgb(205, 205, 205);
  border-radius: 10px;
}

.reseller-order-summary-totalorder {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.reseller-ordersummary-confirmedbtn {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
}

.reseller-billing-container p {
  font-size: 14px;
}

.reseller-billing-text-title p {
  font-size: 14px;
}

.reseller-billing-confirmedbtn {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cost-contianer {
  width: 100%;
  height: 60px;
  background-color: rgb(244, 244, 244);
  border-radius: 10px;
}

.cost-text-style {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cost-text-style p {
  padding-left: 20px;
  padding-right: 20px;
}

.stepper-label-style {
  font-size: 14px;
}

/*billing*/

/*billingtype*/
.billingType-mainContainer {
  width: 100%;
  height: calc(100vh - 202px);
}

.billingtype-main-headingcontainer {
  width: 100%;
  height: 50px;
}

.billingtype-main-headingcontainer h3 {
  margin-left: 20px;
}

.billingtype-form-container1 {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
}

.billingtype-form-container2 {
  width: 90%;
  min-height: 200px;
  border-radius: 10px;
}

.billingtype-form-input1 {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.billingtype-form-input1-1 {
  width: 90%;
  border-bottom: 1px solid rgb(201, 201, 201);
}

.billingtype-form-input1-3 {
  width: 90%;
}

.billingtype-btncontaienr-1 {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
}

.billingtype-btncontaienr-2 {
  width: 90%;
  display: flex;
  justify-content: center;
}

.stepper-end-border {
  border-bottom: 1px solid rgb(222, 222, 222);
}

/*bilingtype*/

/*costSummary*/
.costSummary-container {
  border: 1px solid rgb(205, 205, 205);
  width: 100%;
  min-height: 450px;
  border-radius: 10px;
}

.cost-contianer {
  width: 100%;
  height: 60px;
  background-color: rgb(244, 244, 244);
  border-radius: 10px;
}

.cost-text-style {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cost-text-style p {
  padding-left: 20px;
  padding-right: 20px;
}

.cost-summary-totalorder {
  width: 100%;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*costSummary*/
/*payment*/
/* .paypal-input-contianer{
    width: 90%;
    min-height: 70px;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.paypal-input-container-style{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
} */

.stepper-payemnt-container-1 {
  width: 100%;
  min-height: 70px;
  display: flex;
  justify-content: center;
}

.stepper-payemnt-contianer-2 {
  width: 100%;
  height: 70px;
  display: flex;
  padding: 0 25px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(231, 231, 231);
}

.stepper-payemnt-buttoncontainer {
  width: 90%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-summary-container-1 {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.order-summary-container-2 {
  width: 85%;
  height: 35px;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  align-items: center;
  border: 1px solid rgb(244, 244, 244);
  border-radius: 5px;
  background-color: rgb(244, 244, 244);
  margin-top: 10px;
  margin-bottom: 10px;
}

.deleteiconStyle {
  color: red;
}

/*order fonts*/

.order-summary-names {
  font-size: 12px;
  font-weight: bold;
}

.order-summary-count-box {
  width: 30px;
  height: 20px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #fff;
  text-align: center;
}

.payment-order-summary-totalcontainer {
  border-top: 1px solid rgb(214, 214, 214);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 3%;
  margin-right: 3%;
}

.order-summary-totalamt-contianer {
  width: 90%;
  height: 35px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}

.order-summary-btn-contianer {
  width: 90%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*payment*/

/*branding*/
.branding-main-contianer {
  margin: 20px;
}

.branding-contianer {
  display: flex;
  width: 100%;
  min-height: 400px;
}

/*branding*/

/*customer*/
/* card layout using flex */

.flex-contianer {
  display: flex;
  flex-wrap: wrap;
}

.flex-items {
  flex: 100%;
  margin: 10px;
}

/* mobile */
@media only screen and (max-width: 430px) {
  .flex-items {
    flex: 100%;
  }
}

/* tabs */
@media only screen and (max-width: 600px) {
  .flex-items {
    flex: 50%;
  }
}

/* laptop */
@media only screen and (max-width: 900px) {
  .flex-items {
    flex: 25%;
  }
}

/* monitor */
@media only screen and (max-width: 1200px) {
  .flex-items {
    flex: 20%;
  }
}

/* tv */
@media only screen and (min-width: 1536px) {
  .flex-items {
    flex: 20%;
  }

  /*.MuiContainer-maxWidthXl {*/
  /*    max-width: 100% !important;*/
  /*}*/
}

.card-logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.card-container-warning {
  width: 100%;
  min-height: 200px;
  border: 2px solid red;
  border-radius: 5px;
  position: relative;
}

.exclamatory-icon-contianer {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -30px;
  margin-left: -30px;
}

.card-container {
  width: 100%;
  min-height: 200px;
  padding-bottom: 15px;
}

.inactive-status {
  color: orange !important;
}

.left-dot {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: right;
  align-items: flex-end;
}

.left-dot:hover {
  cursor: pointer;
}

.card-title-contianer {
  text-align: center;
  font-weight: 900 !important;
  font-size: 22px !important;
  margin: 0 !important;
}

.card-flag-icons {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Status-container {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Status-container .inactive-status {
  border-bottom: 1px solid orange !important;
}

.Status-container span {
  font-size: 20px;
  color: green;
  padding-bottom: 10px;
  border-bottom: 1px solid green;
}

.registered-status-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.registeredon_customer {
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  padding-bottom: 10px !important;
}

.registereddate_customer {
  color: rgb(198, 198, 198);
  font-size: 12px;
  text-align: center;
}

.secondary_tabs_title {
  font-size: 20px;
  letter-spacing: 0.3px;
  font-weight: 100;
  color: #9a9a9a;
  text-transform: none;
}

/*customerDetails*/
.Dash_main_container {
  padding-top: 10px;
}

.dash_breadgrums_text_inactive {
  color: #797979;
  font-size: 14px !important;
}

.dash_breadgrums_text_active {
  color: #484848;
  font-size: 14px !important;
}

.dash_container_row2 {
  margin-top: 25px;
  margin-bottom: 10px;
}

.paper1 {
  width: 100%;
  min-height: 352px;
}

.addressBox_title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.addressBox_label {
  font-size: 10px;
  font-weight: 100;
}

.dash_container_row2 {
  width: 100%;
  min-height: 100px;
}

.reseller_dash_card_container {
  padding: 20px 30px 20px 30px;
}

.Reseller_card {
  width: 250px;
  min-height: 300px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #aaaaaa;
  margin: 15%;
}

.card_logo_container {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_logo_title_container {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_logo_title_container span {
  color: black;
  font-weight: 900;
  font-size: 16px;
}

.card_services_icon {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.card_active_status_container {
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.card_active_status_container span {
  color: #0fc116;
  font-size: 10px;
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 15px;
}

.card_register_cotnainer {
  padding: 20px 20px 40px 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.card_register_container_alignment {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.card_status_title {
  color: black;
  font-weight: 900;
  font-size: 10px;
  display: flex;
  justify-content: center;
}

.card_status_data {
  color: #999999;
  font-weight: 100;
  font-size: 9px;
  display: flex;
  padding-top: 10px;
  justify-content: center;
}

.activeCard {
  border: 2px solid #f8f8f8;
  border-radius: 5px;
}

.warningCard {
  border: 2px solid #ca1e39;
  border-radius: 5px;
}

.warningCard_text {
  color: #ffb314 !important;
  font-size: 10px;
}

/*Customer Last Bill*/
.border_left_right {
  border-left: 1px solid rgba(192, 192, 192, 0.29);
  border-right: 1px solid rgba(192, 192, 192, 0.29);
  padding: 0 40px;
  margin: 10px 40px;
}

.last_billing_title {
  color: silver;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.addressBox_title_error {
  color: red;
}

/*customerDetails*/

/*dashboard*/
.container {
  min-width: 100%;
  min-height: 310px;
  display: flex;
  align-items: center;
  margin-bottom: 2%;
}

.paper_Container {
  width: 100%;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  justify-content: center;
}

/* notification container style */

.notification_container {
  height: 300px;
}

.notification_title_container {
  width: 100%;
  height: 50px;
}

.notifiaction_scroll_container {
  display: flex;
  flex-direction: column;
  height: 250px;
  overflow-y: auto;
}

.notification_content_container {
  display: flex;
  padding: 10px 20px;
}

.notification_text_container {
  font-size: 14px;
  padding-left: 10px;
}

/* billing container style */

.billing_container {
  height: 300px;
}

.billing_title_container {
  width: 100%;
  height: 50px;
}

.billing_date_container {
  width: 100%;
  min-height: 50px;
}

.billing_services_container {
  width: 100%;
  min-height: 70px;
  margin: 20px 0px;
}

.billing_divider_container {
  width: 100%;
  min-height: 10px;
  line-height: 50px;
}

.billing_amount_container {
  width: 100%;
  min-height: 80px;
  display: flex;
  align-items: center;
}

/* scroll bar style */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 15px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0984e3;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}

/* charts styles */

/* donut chart style */

.donut_chart_container {
  width: 100%;
  min-height: 320px;
}

.bar_chart_container {
  width: 100%;
  min-height: 320px;
}

/* alignments */
.vertical_align {
  display: flex;
  align-items: center;
}

.bar_chart_revenue_alignment {
  min-height: 241px;
  display: flex;
  align-items: center;
}

/*homeDashboard*/

.dashboard-box-style {
  width: 100%;
  height: 125px;
}

.box-style-row1 {
  width: 100%;
  height: 80px;
}

.box-style-row2 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
}

.box-style-row1 p {
  margin: 0;
  font-size: 60px;
  font-weight: 900;
  padding-left: 20px;
  display: flex;
  height: 90px;
  align-items: center;
  margin: 0;
}

.total {
  font-size: 21px;
  font-weight: 100;
  letter-spacing: 0.7px;
  padding-left: 20px;
  color: rgb(101, 101, 101);
  margin: 0;
  display: flex;
  align-items: center;
}

.value {
  font-size: 14px;
  font-weight: 900;
  color: #19c420;
}

.arrowupstyle {
  color: #19c420;
  font-weight: 900;
}

.homedashboard-horizontal-halfs {
  height: 37vh;
}

.notification-container {
  width: 100%;
  height: 275px;
  overflow-y: scroll;
}

.notification-title {
  font-size: 36px;
  padding: 10px 10px 0px 15px;
  font-weight: 900;
}

.billing-summary-title {
  font-size: 36px;
  padding: 10px 10px 0px 15px;
  font-weight: 900;
}

.notification-list-container {
  padding: 0px 15px 15px 15px;
  min-height: 50px;
  display: flex;
  flex-direction: row;
}

.success-notification {
  font-size: 18px !important;
  padding-right: 10px;
  margin-top: 4px;
  margin-right: 0px;
  margin-left: -25px;
}

.success-notification-blank {
  font-size: 18px !important;
  padding-right: 10px;
  margin-top: 4px;
  margin-right: -15px;
}
.text-cut {
  width: 200px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notification-container::-webkit-scrollbar {
  width: 8px;
}

.notification-container::-webkit-scrollbar-track {
  border-left: #c9c9c9 solid 1px;
  margin-top: 5px;
}

.notification-container::-webkit-scrollbar-thumb {
  background-color: #4682b4;
  border-radius: 8px;
  border: 1px solid #4682b4;
}

.homedashboard-billsummary-container {
  width: 100%;
  height: 275px;
}

.bill-due-container {
  height: 30px;
  padding-left: 20px !important;
}

.bill-due-container p {
  margin: 0;
}

.bill-due-amount-container {
  padding: 10px 10px 0px 20px;
}

.bill-due-amount-name p {
  margin: 0;
}

.bill-due-amount p {
  margin: 0;
  text-align: right;
  padding-right: 20px;
}

/* custom divider */
.custom-divider {
  margin: 20px 5px 0px 5px;
  border: 1px solid #878787;
}

.due-total-payable {
  padding: 0px 10px 20px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.due-curreny {
  font-size: 30px;
  font-weight: 900;
  display: flex;
  align-items: flex-start;
}

.homedashboard-horizontal-second-container {
  height: 50vh;
  margin-top: 30px;
}

.customers-by-revenue-container {
  width: 100%;
  height: 40vh;
}

.customer-by-revenue-title-container {
  display: flex;
  min-height: 1px;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;
}

.customer-by-revenue-title-container span {
  font-size: 40px;
  font-weight: 600;
}

.customer-by-revenue-icon {
  font-size: 40px !important;
  height: 60px;
  display: flex;
  align-items: center;
}

.barChartContainer {
  width: 100%;
  height: 32vh;
}

.barchartpriceContainer {
  width: 100%;
  height: 32vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.amount1 {
  font-size: 60px;
  font-weight: 900;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.revenueLoss {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  width: 100%;
  justify-content: center;
}

/* new styles */

.card-style-1 {
  width: 100%;
  min-height: 130px;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/*homeDashboard*/

/*resellerDetails*/
.Dash_main_container {
  padding-top: 10px;
}

.dash_breadgrums_text_inactive {
  color: #797979;
  font-size: 14px !important;
}

.dash_breadgrums_text_active {
  color: #484848;
  font-size: 14px !important;
}

.dash_container_row2 {
  margin-top: 25px;
  margin-bottom: 10px;
}

.paper1 {
  width: 100%;
  min-height: 352px;
  padding: 20px 60px 10px 20px;
}

.addressBox_title {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.addressBox_label {
  font-size: 10px;
  font-weight: 100;
}

.dash_container_row2 {
  width: 100%;
  min-height: 100px;
  padding: 0px 20px 20px 20px;
}

.reseller_dash_card_container {
  padding: 20px 30px 20px 30px;
}

.Reseller_card {
  width: 250px;
  min-height: 300px;
  background-color: #fff;
  box-shadow: 2px 2px 10px #aaaaaa;
  margin: 15%;
}

.card_logo_container {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_logo_title_container {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_logo_title_container span {
  color: black;
  font-weight: 900;
  font-size: 16px;
}

.card_services_icon {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.card_active_status_container {
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.card_active_status_container span {
  color: #0fc116;
  font-size: 10px;
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 15px;
}

.card_register_cotnainer {
  padding: 20px 20px 40px 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.card_register_container_alignment {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.card_status_title {
  color: black;
  font-weight: 900;
  font-size: 10px;
  display: flex;
  justify-content: center;
}

.card_status_data {
  color: #999999;
  font-weight: 100;
  font-size: 9px;
  display: flex;
  padding-top: 10px;
  justify-content: center;
}

.activeCard {
  border: 2px solid #f8f8f8;
  border-radius: 5px;
}

.warningCard {
  border: 2px solid #ca1e39;
  border-radius: 5px;
}

.warningCard_text {
  color: #ffb314 !important;
  font-size: 10px;
}

/*resellerDetails*/

/*contact*/
.contact-main-container {
  width: 100%;
  min-height: 50vh;
}

.main-container {
  margin: 20px;
}

.billing-address-container {
  margin: 20px;
}

.shipping-address-container {
  margin: 20px;
}

.billing-address-container span {
  font-size: 16px;
  font-weight: 900;
}

.shipping-address-container span {
  font-size: 16px;
  font-weight: 900;
}

.shipping-address-title-contianer {
  width: 100%;
  height: 50px;
  align-items: center;
  display: flex;
}

/*contact*/
/*notification*/
.notification-main-contianer {
  margin: 20px;
}

.notificaion-title {
  font-size: 20px;
  font-weight: 700;
}

/*notification*/

/*payment*/
.card-container {
  width: 100%;
}

.stored-credit-cards {
  margin: 0;
  font-size: 14px;
}

.slight-gray-style {
  color: rgb(184, 184, 184);
  margin-top: 10px;
  font-size: 16px;
}

.add-new-card {
  border-radius: 20px !important;
  /*border-bottom-left-radius: 20px !important;*/
  /*border-bottom-right-radius: 20px !important;*/
  /*border-top-right-radius: 20px !important;*/
}

.credit-cards-container {
  margin-top: 20px;
  width: 100%;
  height: 170px;
  border: 1px solid #b7b7b7;
  border-radius: 10px;
  box-shadow: 5px 5px 20px #aaaaaa;
  position: relative;
}

.credit-cards-container:hover {
  box-shadow: 10px 10px 30px #aaaaaa;
  cursor: pointer;
}

.credit-card-row1 {
  width: 100%;
  height: 50px;
  background-color: #323746;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.credit-card-cvv-style {
  margin-right: 20px;
  color: #fff;
  line-height: 50px;
}

.credit-cards-row2 {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c2c2c2;
}

.credit-cards-row3 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.default-payment-rowstyle {
  height: 12px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.credit-cards-container2 {
  width: 100%;
  min-height: 150px;
}

.credit-cards-container2-row1 {
  background-color: blue;
  display: flex;
  height: 50px;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  justify-content: space-between;
  color: #fff;
}

.credit-cards-contianer2-row2 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.credit-cards-container2-row3 {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}

.credit-cards-container2-row3 span {
  font-size: 12px;
  padding-left: 20px;
}

.text {
  white-space: nowrap;
  color: #fff;
  font-size: 20px;
  opacity: 1;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.credit-cards-container:hover .overlay {
  bottom: 0;
  height: 100%;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
  border-radius: 10px;
}

.slide-container-position {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide-container-box-style {
  min-width: 100px;
  min-height: 100px;
  background-color: white;
}

.slide-editpayment-container {
  margin: 20px;
}

.creditCardEditIcon :hover {
  font-size: 16px !important;
  cursor: pointer;
}

/*payment*/

.thead tr th {
  font-size: 14px;
  font-weight: bold;
}

.tbody tr td {
  font-size: 14px;
}

.css-1l8j5k8 {
  border-right: none !important;
}

.services-header-font {
  font-size: 16px;
  color: #5d5d5d;
}

.service-list {
  width: 100%;
  height: 50px;
  background-color: rgb(244, 244, 244);
  border-radius: 5px;
}

.service-text {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.label {
  color: rgb(157 157 157);
  font-size: 15px;
  margin: 4px 0;
  display: block;
}

.stepper-label {
  color: gray;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
  letter-spacing: 0.5px;
}

/* sidebar  */

.css-6siulo-MuiButtonBase-root-MuiTab-root {
  padding: 0 !important;
}

.borderless-card {
  border: none !important;
  box-shadow: none !important;
}

.card-hover:hover {
  background: rgba(74, 182, 252, 0.23);
  cursor: pointer;
}

.card-hover.selected-card {
  background: rgba(74, 182, 252, 0.82);
  color: #fff;
}

.numbers-chip {
  border: 1px solid silver;
  padding: 5px;
  width: 92%;
  text-align: center;
  cursor: pointer;
}
.numbers-chip-multiple {
  border: 1px solid silver;
  padding: 5px;
  /*width: 100px;*/
  text-align: center;
  cursor: pointer;
}

.selected-numbers-chip {
  background: rgba(74, 182, 252, 0.82);
  color: #fff;
}

.icon-container {
  padding: 10px 2px;
  margin: 0 5px;
  cursor: pointer;
}

input[type='file'] {
  opacity: 0;
  height: 48px;
  width: 48px;
}

.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #4245a8;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

/*cursor pointer*/

.cursorStyle_pointer {
  cursor: pointer;
}

/*for removing icons in input type number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: inherit;
}

.card-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70px;
  width: 100%;
  padding: 5px 20px;
}
input:-webkit-autofill {
  background-color: white !important;
  padding: 5px;
}

.MuiTooltip-tooltip {
  font-size: 14px !important;
}

.tab-title {
  margin: 5px 0 -3px -4px !important;
  font-size: 17px !important;
}
