.mycard:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.mycard:hover {
  cursor: crosshair !important;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.approvalstatusBorder {
  border: 1px solid red;
}

.cardinactive {
  border: 1px solid red;
  border-radius: 5px;
}

.cardactive {
  border: 1px solid #fff;
  border-radius: 5px;
}

.MuiContainer-root .card-common {
  padding: 0 0px;
  width: auto;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(58, 58, 68, 0.24), 0px 2px 4px rgba(90, 91, 106, 0.24) !important;
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}

.card-content-common {
  width: '367px';
    height: '36px';
    background-color: '#E3E7EF';
    display: 'flex';
    align-items: 'center';
    justify-content:'left';


    margin-bottom: '16px';
    margin-left: '-16px';
    margin-top: '-16px';
}


.card-text{

  opacity: 1;
  color: rgba(23,43,78,0.87) !important;
  /* font-family: "SourceSansPro-SemiBold" !important; */
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.34px;
  text-align: left;

}

.card-label{

 
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  
}
