.loginpage_main_container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: left;
}

.login_container {
  width: 450px;
  height: 450px;
}

.illustrator-container {
  /* width: 100%;
  height: 100vh; */
  display: flex;
  align-items: center;
  margin: 20px;
}

.image-position {
  height: auto;
  width: auto;
  border-radius: 15px;
  max-height: 100%;
  max-width: 100%;
}

.logo_container {
  width: 100%;
  height: 100px;
  display: block;
  text-align: left;
}

.textfield_container {
  padding: 20px;
  height: 300px;
  text-align: left;
}

.microsoftsignin_container {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: left;
  /* box-shadow: '0px 1px 3px rgba(50,104,185, 0.2), 0px 2px 4px rgba(50,104,185, 0.26), 0px 0px 2px rgba(50,104,185, 0.14)';
  background-color: transparent;
  border-radius: 6.5px;
  border: 1px solid #e6e6e6; */
}

.lineDiv {
  border: 1px solid;
  border-color: rgba(231, 231, 231, 1);
  opacity: 1;
  margin-top: 19px;
  width: 320px;
}

.stepper-input-label-margin {
  margin: 10px 20px;
}

.stepper-label-style {
  color: rgb(157 157 157);
  font-size: 12px;
}

/* forgot password page */

.forgotpasswordPage_main_Container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: left;
}

.forgotpasswordPage_Container {
  width: 450px;
  height: 350px;
}

/* reset password page */

.resetpassword_main_container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.resetpassword_container {
  width: 450px;
  height: 350px;
}

.progress-bg {
  width: 100%;
  height: 0.25rem;
  background-color: #fbfbfb;
  border-radius: 0 0 0.2rem 0.2rem;
  position: relative;
}

.progress {
  width: 0%;
  height: 0.25rem;
  position: absolute;
  border-radius: 0 0 0.2rem 0.2rem;
  transition: all 0.5s ease-out;
}

.pwdmessage {
  font-size: 0.7rem;
  font-weight: 500;
  margin-top: 0.8rem;
  letter-spacing: 0.05rem;
  color: #ff6837;
}
