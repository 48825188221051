
/* notification container style */

.notificationbtn_container {
    height: 320px;
    width: 300px;
  }

  .notificationbtn_container:hover{
    cursor: pointer;
  }
  
  .notificationbtn_title_container {
    width: 100%;
    height: 50px;
  }
  
  .notifiactionbtn_scroll_container {
    display: flex;
    flex-direction: column;
    height: 250px;
    overflow-y: auto;
  }

  .css-6hp17o-MuiList-root-MuiMenu-list{
    padding: 0 !important;
  }
  
  .notificationbtn_content_container {
    display: flex;
    padding: 10px 20px;
  }
  
  .notificationbtn_text_container {
    font-size: 14px;
    padding-left: 10px;
  }

.appendBody img{
  width: 550px;
}
  