.d-flex {
  display: flex;
}
.d_flex_column {
  display: flex;
  flex-direction: column;
}
.fullWidth {
  width: 100%;
}
.gap-1 {
  gap: 1rem;
}

.space-between {
  justify-content: space-between;
}
.align-center{
  align-items: center;
  justify-content: center;
}