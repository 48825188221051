.billingType-mainContainer{
    width: 100%;
    height: calc(100vh - 202px);
}

.billingtype-main-headingcontainer{
    width: 100%;
    height: 50px;
}

.billingtype-main-headingcontainer h3{
    margin-left: 20px;
}

.billingtype-form-container1{
    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: center;

}
.billingtype-form-container2{
    width: 95%;
    min-height: 200px;
    border-radius: 10px;
    padding: 20px 0px;
}


.billingtype-form-input1{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.billingtype-form-input1-1{
    width: 90%;
    border-bottom: 1px solid rgb(201, 201, 201);
}
.billingtype-form-input1-3{
    width: 90%;
}

.billingtype-btncontaienr-1{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
}

.billingtype-btncontaienr-2{
    width: 90%;
    display: flex;
    justify-content: center;
}
.stepper-end-border{
    border-bottom: 1px solid rgb(222, 222, 222);
}